import { Fragment } from 'react';
import { ErrorMessage, Field, FieldArray, Formik } from 'formik';
import { v4 as uuid } from 'uuid';
import { AnimatePresence, motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { alertAction } from '../../redux/alert/alertAction';
import api from '../../utility/api';
import SubmitCancelButtonWithLoader from '../button/SubmitCancelButtonWithLoader';
import TextError from '../formik/TextError';
import DeleteIcon from '../icons/DeleteIcon';
import CheckTickIcon from '../icons/CheckTickIcon';
import CrossCancelIcon from '../icons/CrossCancelIcon';
// import "./createConference.styles.scss";
import Dropzone from 'react-dropzone';
import TextInput from '../formik/TextInput';
import ImageGalleryIcon from '../icons/ImageGalleryIcon';
import Loader from '../loader/Loader';
import { createPodcastAction } from '../../redux/podcast/podcastAction';
import Switch from '../switch/Switch';

// menuPortalTarget={document.body}
// styles={modalStyleformType4}

const validationSchema = yup.object().shape({
  title: yup.string().trim().required('Required'),
  description: yup.string().trim().required('Required'),
  banner: yup
    .array()
    // .of(yup.object())
    .min(1, 'Required')
    .compact(),
  tags: yup
    .array()
    .of(
      yup.object().shape({
        uuid: yup.string(),
        tag: yup
          .string()
          // .matches(websiteRegax, "Enter correct url")
          .required('Required')
          .test(
            'isFormTest',
            'Save tag using tick icon on right',
            function (value) {
              if (
                // this.parent.credits?.find((e) => e.value === value)
                this.parent.isForm
              ) {
                return false;
              } else {
                return true;
              }
            }
          ),
        isForm: yup.bool(),
      })
    )
    .min(1, 'Add at least one tag'),
  links: yup.array().of(
    yup.object().shape({
      uuid: yup.string(),
      link: yup
        .string()
        // .matches(websiteRegax, "Enter correct url")
        .test(
          'isFormTest',
          'Save links using tick icon on right',
          function (value) {
            if (
              // this.parent.credits?.find((e) => e.value === value)
              this.parent.isForm
            ) {
              return false;
            } else {
              return true;
            }
          }
        ),
    })
  ),
  media: yup.array().min(1).required('Required'),
});

export default function CreatePodcastStep2() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const newPodcast = useSelector((state) => state.podcast.newPodcast);

  async function onSubmit(values, actions) {
    if (newPodcast?.completedStep1) {
      const { tags, links, title, description, banner, media, preview } =
        values;
      const formData = {
        mediaDetails: {
          tags,
          mediaId: newPodcast?._id,
          links,
          title,
          description,
          banner,
          source: media,
          episodeId: newPodcast?.savedEpisode?._id,
          preview: preview,
        },
      };

      try {
        const response = await api.post(
          `/podcast/${newPodcast?._id}/step2`,
          formData
        );

        if (response) {
          dispatch(createPodcastAction(response.data.data.media));
          navigate('/dashboard/create-pod/step-3');
          dispatch(alertAction(response.data.message, 'success'));
        }
      } catch (err) {
        dispatch(alertAction(err.response.data.message, 'danger'));
      }
    } else {
      dispatch(alertAction('Complete step-1 first', 'danger'));
    }
  }

  const initialValues = {
    tags:
      !newPodcast?.savedEpisode?.tags?.length > 0
        ? []
        : newPodcast?.savedEpisode?.tags,
    links:
      !newPodcast?.savedEpisode?.links?.length > 0
        ? []
        : newPodcast?.savedEpisode?.links,
    title: newPodcast?.savedEpisode?.title || '',
    description: newPodcast?.savedEpisode?.description || '',
    banner: newPodcast?.savedEpisode?.banner || [],
    media: newPodcast?.savedEpisode?.source
      ? [newPodcast?.savedEpisode?.source]
      : [],
    preview: newPodcast?.preview,
  };

  // load credit types from backend

  return (
    <main className="create-event-wrap">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize={true}
      >
        {(props) => {
          return (
            <form
              className="form-type-4"
              autoComplete="off"
              onSubmit={props.handleSubmit}
            >
              <h2 className="color-primary mb-16">Create Episode</h2>

              <div className="mb-32">
                <p
                  className="body-regular-gray3 mb-24"
                  style={{ color: '#aabdc7' }}
                >
                  All mandatory fields are makred by *
                </p>
                <p className="avenir-20-500  mb-8">Episode title *</p>
                <TextInput
                  name="title"
                  type="text"
                  autoComplete="off"
                  autoCapitalize="off"
                  autoCorrect="off"
                />
              </div>
              <div className="mb-24">
                <h4 className="avenir-20-500 mb-8">Description *</h4>
                <Field
                  id="description"
                  name="description"
                  as="textarea"
                  type="text"
                  cols="10"
                  rows="5"
                  placeholder="Description"
                />
                <ErrorMessage name="description" component={TextError} />
              </div>
              <div className="mb-32">
                <h4 className="avenir-20-500 mb-8">Add podcast banner image</h4>
                {/* className="confbanner-dropzone-container " controls
                        the size of SingleImageUploader */}
                <div className="event-banner-dropzone-container position-relative">
                  {props.values?.banner?.length > 0 ? (
                    props.values.banner?.map((image) => (
                      <div key={image.Key} className="confbanner-container">
                        <div className="confbanner-wrap">
                          <img
                            className="confbanner"
                            alt="course banner"
                            src={image.Location}
                            // Revoke data uri after image is loaded
                            // onLoad={() => {
                            //   URL.revokeObjectURL(image.Location);
                            // }}
                          />
                        </div>
                        <div className="confbanner-overlay"></div>
                        <div
                          //   onClick={() =>
                          //     deleteFile(
                          //       image?.Key,
                          //       'banner',
                          //       props.values.banner,
                          //       props.setFieldValue
                          //     )
                          //   }
                          className="confbanner-delete-circle"
                        >
                          <DeleteIcon className="icon-size" />
                        </div>
                      </div>
                    ))
                  ) : props.values.isBannerLoading ? (
                    <div className="singleimage-dropzone">
                      <Loader />
                    </div>
                  ) : (
                    <Dropzone
                      // accept="video/mp4"
                      multiple={false}
                      // maxSize={524288000}
                      maxFiles={1}
                      onDrop={async (acceptedFiles) => {
                        if (acceptedFiles?.length > 0) {
                          const dataObj = new FormData();
                          let oldMedia = [];
                          acceptedFiles.map((item) =>
                            !item.Key
                              ? dataObj.append('file', item)
                              : oldMedia.push(item)
                          );

                          if (dataObj.has('file')) {
                            props.setFieldValue('isBannerLoading', true);
                            try {
                              const mediaResponse = await api.post(
                                'media/upload',
                                dataObj
                              );
                              if (mediaResponse) {
                                props.setFieldValue(
                                  'banner',
                                  mediaResponse.data.data
                                );
                              }
                              props.setFieldValue('isBannerLoading', false);
                            } catch (err) {
                              props.setFieldValue('isBannerLoading', false);
                              dispatch(
                                alertAction('Media failed to save', 'danger')
                              );
                            }
                          }
                        }
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div
                          {...getRootProps({
                            className: 'singleimage-dropzone',
                          })}
                        >
                          <div className="text-align-center">
                            <i>
                              <ImageGalleryIcon className="icon-lg" />
                            </i>
                            <p className="caption-1-medium-primary">
                              <u> Add file</u>
                            </p>
                          </div>
                          <input {...getInputProps()} />
                        </div>
                      )}
                    </Dropzone>
                  )}
                </div>
                <ErrorMessage name="banner" component={TextError} />
              </div>
              <div className="mb-32">
                <p className="avenir-20-500 mb-12">Add episode media *</p>
                <div className="mb-16 position-relative">
                  {props.values.media?.length > 0 ? (
                    props.values.media.map((item) => (
                      <div
                        key={item?.name}
                        className="media-preview-wrap position-relative"
                      >
                        <video
                          controls
                          style={{ width: '100%', maxWidth: '100%' }}
                        >
                          <source src={item.Location} type={item?.type} />
                        </video>
                        <div
                          className="change-media-button"
                          onClick={() => {
                            if (props.values.media[0]?.key) {
                              props.setFieldValue(
                                'deteledmedia',
                                props.values.media[0]?.key
                              );
                            }
                            props.setFieldValue('media', []);
                          }}
                        >
                          <p className="caption-2-regular-gray3">
                            <u style={{ color: '#fff' }}>Change file</u>
                          </p>
                        </div>
                      </div>
                    ))
                  ) : props.values.isMediaLoading ? (
                    <div className="singlefile-dropzone">
                      <Loader />
                    </div>
                  ) : (
                    <div>
                      <Dropzone
                        // accept={'audio/*'}
                        // accept="video/mp4"
                        multiple={false}
                        maxFilesize={833940315}
                        maxFiles={1}
                        onDrop={async (acceptedFiles) => {
                          // const acceptedFilesWithUrl = acceptedFiles.map(
                          //   (file) =>
                          //     Object.assign(file, {
                          //       Location: URL.createObjectURL(file),
                          //     })
                          // );
                          // props.setFieldValue(
                          //   "media",
                          //   acceptedFilesWithUrl
                          // );

                          if (acceptedFiles?.length > 0) {
                            const dataObj = new FormData();
                            let oldMedia = [];
                            acceptedFiles.map((item) =>
                              !item.Key
                                ? dataObj.append('file', item)
                                : oldMedia.push(item)
                            );

                            if (dataObj.has('file')) {
                              try {
                                props.setFieldValue('isMediaLoading', true);
                                const mediaResponse = await api.post(
                                  'media/upload',
                                  dataObj
                                );
                                props.setFieldValue('isMediaLoading', false);
                                if (mediaResponse) {
                                  props.setFieldValue(
                                    'media',
                                    mediaResponse.data.data
                                  );
                                  // formData.mediaDetails.source = [
                                  //   ...oldMedia,
                                  //   ...mediaResponse.data.data,
                                  // ];
                                }
                              } catch (err) {
                                props.setFieldValue('isMediaLoading', false);
                                dispatch(
                                  alertAction('Media failed to save', 'danger')
                                );
                              }
                            }
                          }
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <section>
                            <div
                              {...getRootProps({
                                className: 'media-dropzone flex-vchc',
                              })}
                            >
                              <div className="text-align-center">
                                <i>
                                  <ImageGalleryIcon className="icon-lg" />
                                </i>
                                <p className="caption-1-medium-primary">
                                  <u> Add file</u>
                                </p>
                              </div>
                              <input {...getInputProps()} />
                            </div>
                          </section>
                        )}
                      </Dropzone>
                    </div>
                  )}
                  <ErrorMessage name="media" component={TextError} />
                </div>
              </div>
              <div className="mb-32">
                <p className="avenir-20-500 mb-12">Preview</p>
                <div className="mb-12">
                  <Switch
                    id="preview"
                    name="preview"
                    value={props.values.preview}
                    checked={props.values.preview}
                    onChange={props.handleChange}
                    disable={false}
                  />
                </div>
                <p className="body-regular-gray3" style={{ color: '#aabdc7' }}>
                  <span>
                    If preview is turned on, this chapter will be availabe to
                    view as free during course preview.
                  </span>
                </p>
              </div>
              <div>
                <div className="mb-40">
                  <h4 className="avenir-20-500 mb-8">Add links *</h4>

                  <AnimatePresence initial={false} mode="wait">
                    <FieldArray
                      name="links"
                      render={(arrayHelpers) => {
                        const links = arrayHelpers.form.values.links;
                        let indxOfLastItem = 0;
                        if (links?.length > 0) {
                          indxOfLastItem = links?.length - 1;
                        }
                        return (
                          <Fragment>
                            {links?.length > 0 && (
                              <div
                                // grid for tags
                                className="ce-points-grid mb-16"
                              >
                                {links?.map((link, index) => {
                                  return (
                                    <motion.div
                                      // grid item
                                      key={index}
                                      className={
                                        link.isForm ? 'full-width-griditem' : ''
                                      }
                                      layout
                                      initial={{
                                        scale: 0.5,
                                        opacity: 0,
                                      }}
                                      animate={{
                                        scale: 1,
                                        opacity: 1,
                                      }}
                                      exit={{
                                        scale: 0.5,
                                        opacity: 0,
                                      }}
                                      transition={{
                                        type: 'spring',
                                        duration: 0.5,
                                      }}
                                    >
                                      <div className="position-relative">
                                        <Field
                                          disabled={!link.isForm}
                                          style={{ paddingRight: 36 }}
                                          type="text"
                                          name={`links.${index}.link`}
                                          placeholder="Add external links *"
                                          // validate={validateTag}
                                        />
                                        <i
                                          style={{
                                            right: 8,
                                            cursor: 'pointer',
                                          }}
                                          className="right-input-icon"
                                          onClick={() =>
                                            arrayHelpers.remove(index)
                                          }
                                        >
                                          <CrossCancelIcon className="icon-size" />
                                        </i>

                                        <div
                                          className={
                                            !link.isForm
                                              ? 'display-none'
                                              : 'ce-hanging-buttons-wrap'
                                          }
                                        >
                                          <i
                                            style={{
                                              cursor: 'pointer',
                                            }}
                                            onMouseDown={() => {
                                              props.setFieldTouched(
                                                `links.${index}.link`
                                              );
                                              props.validateField('links');
                                              if (link.link !== '') {
                                                const points = links?.map(
                                                  (item) =>
                                                    item.uuid === link.uuid
                                                      ? {
                                                          ...item,
                                                          isForm: false,
                                                        }
                                                      : item
                                                );
                                                arrayHelpers.form.setFieldValue(
                                                  'links',
                                                  points
                                                );
                                              }
                                            }}
                                          >
                                            <CheckTickIcon className="icon-size" />
                                          </i>
                                        </div>
                                      </div>
                                      <ErrorMessage
                                        name={`links.${index}.link`}
                                        component={TextError}
                                      />
                                    </motion.div>
                                  );
                                })}
                              </div>
                            )}
                            <div
                              // outside kyepoints grid
                              // this div controls size of the button
                              style={{
                                width: '24rem',
                              }}
                            >
                              <motion.button
                                disabled={
                                  links[indxOfLastItem]?.tag === '' ||
                                  links[indxOfLastItem]?.isForm
                                }
                                className="ce-insert-button flex-vc"
                                whileTap={{ scale: 0.95 }}
                                type="button"
                                onClick={() =>
                                  arrayHelpers.push({
                                    uuid: uuid(),
                                    link: '',
                                    isForm: true,
                                  })
                                }
                              >
                                + Add links
                              </motion.button>
                            </div>
                          </Fragment>
                        );
                      }}
                    />
                  </AnimatePresence>
                  {props.touched.links &&
                    Boolean(props.errors.links) &&
                    (typeof props.errors.links === 'string' ? (
                      <TextError>{props.errors.links}</TextError>
                    ) : null)}
                </div>
              </div>
              <div className="create-event-grid">
                <div
                // first limb of the grid
                >
                  <div className="mb-40">
                    <h4 className="avenir-20-500 mb-8">
                      Improve Searchability with Tags *
                    </h4>
                    <p
                      className="caption-1-regular-gray3 mb-8"
                      style={{ color: '#aabdc7' }}
                    >
                      Tag is a key word or combination of words if searched by
                      user, will bring up this video course.
                    </p>
                    <p
                      className="caption-1-regular-gray3 mb-16"
                      style={{ color: '#aabdc7' }}
                    >
                      Add multiple tags.
                    </p>
                    <AnimatePresence initial={false} mode="wait">
                      <FieldArray
                        name="tags"
                        render={(arrayHelpers) => {
                          const tags = arrayHelpers.form.values.tags;
                          let indxOfLastItem = 0;
                          if (tags?.length > 0) {
                            indxOfLastItem = tags?.length - 1;
                          }
                          return (
                            <Fragment>
                              {tags?.length > 0 && (
                                <div
                                  // grid for tags
                                  className="ce-points-grid mb-16"
                                >
                                  {tags?.map((tag, index) => {
                                    return (
                                      <motion.div
                                        // grid item
                                        key={index}
                                        className={
                                          tag.isForm
                                            ? 'full-width-griditem'
                                            : ''
                                        }
                                        layout
                                        initial={{
                                          scale: 0.5,
                                          opacity: 0,
                                        }}
                                        animate={{
                                          scale: 1,
                                          opacity: 1,
                                        }}
                                        exit={{
                                          scale: 0.5,
                                          opacity: 0,
                                        }}
                                        transition={{
                                          type: 'spring',
                                          duration: 0.5,
                                        }}
                                      >
                                        <div className="position-relative">
                                          <Field
                                            disabled={!tag.isForm}
                                            style={{ paddingRight: 36 }}
                                            type="text"
                                            name={`tags.${index}.tag`}
                                            placeholder="improve searchability with tags *"
                                            // validate={validateTag}
                                          />
                                          <i
                                            style={{
                                              right: 8,
                                              cursor: 'pointer',
                                            }}
                                            className="right-input-icon"
                                            onClick={() =>
                                              arrayHelpers.remove(index)
                                            }
                                          >
                                            <CrossCancelIcon className="icon-size" />
                                          </i>

                                          <div
                                            className={
                                              !tag.isForm
                                                ? 'display-none'
                                                : 'ce-hanging-buttons-wrap'
                                            }
                                          >
                                            <i
                                              style={{
                                                cursor: 'pointer',
                                              }}
                                              onMouseDown={() => {
                                                props.setFieldTouched(
                                                  `tags.${index}.tag`
                                                );
                                                props.validateField('tags');
                                                if (tag.tag !== '') {
                                                  const points = tags?.map(
                                                    (item) =>
                                                      item.uuid === tag.uuid
                                                        ? {
                                                            ...item,
                                                            isForm: false,
                                                          }
                                                        : item
                                                  );
                                                  arrayHelpers.form.setFieldValue(
                                                    'tags',
                                                    points
                                                  );
                                                }
                                              }}
                                            >
                                              <CheckTickIcon className="icon-size" />
                                            </i>
                                          </div>
                                        </div>
                                        <ErrorMessage
                                          name={`tags.${index}.tag`}
                                          component={TextError}
                                        />
                                      </motion.div>
                                    );
                                  })}
                                </div>
                              )}
                              <div
                                // outside kyepoints grid
                                // this div controls size of the button
                                style={{
                                  width: '24rem',
                                }}
                              >
                                <motion.button
                                  disabled={
                                    tags[indxOfLastItem]?.tag === '' ||
                                    tags[indxOfLastItem]?.isForm
                                  }
                                  className="ce-insert-button flex-vc"
                                  whileTap={{ scale: 0.95 }}
                                  type="button"
                                  onClick={() =>
                                    arrayHelpers.push({
                                      uuid: uuid(),
                                      tag: '',
                                      isForm: true,
                                    })
                                  }
                                >
                                  + Add tags
                                </motion.button>
                              </div>
                            </Fragment>
                          );
                        }}
                      />
                    </AnimatePresence>
                    {props.touched.tags &&
                      Boolean(props.errors.tags) &&
                      (typeof props.errors.tags === 'string' ? (
                        <TextError>{props.errors.tags}</TextError>
                      ) : null)}
                  </div>

                  <div>
                    <SubmitCancelButtonWithLoader
                      isSubmitting={props.isSubmitting}
                      onCancel={() => props.resetForm({})}
                      fullWidth={true}
                    />
                  </div>
                </div>
                <div>{/* keep empty */}</div>
              </div>
            </form>
          );
        }}
      </Formik>
    </main>
  );
}
