import './dbConfsTable.styles.scss';

export default function DbBookingTable({ bookings }) {
  return (
    <div
      style={{
        boxShadow: 'none',
        borderRadius: '6px',
        border: 'solid 1px #ecf0f2',
      }}
      className="db-confs-table-wrap"
    >
      <table style={{ boxShadow: 'none', backgroundClor: '#fff' }}>
        <thead
          style={{
            borderRadius: '6px',
            backgroundColor: '#fff',
          }}
        >
          <tr>
            <th
              style={{
                // backgroundColor: '#ecf0f2',
                fontSize: '16px',
                color: ' #08415c',
                fontWeight: 500,
              }}
            >
              Latest Bookings
            </th>
            <th
              style={{
                // backgroundColor: '#ecf0f2',
                fontSize: '16px',
                color: ' #08415c',
                fontWeight: 500,
              }}
            >
              Date
            </th>
            <th
              style={{
                // backgroundColor: '#ecf0f2',
                fontSize: '16px',
                color: ' #08415c',
                fontWeight: 500,
              }}
            >
              Amount
            </th>
          </tr>
        </thead>
        <tbody>
          {!bookings?.length > 0 ? (
            <tr>
              <td>No booking yet!</td>
            </tr>
          ) : (
            bookings?.map((booking) => (
              <tr key={booking?._id}>
                <td>{`${booking?.firstName}  ${booking?.lastName}`}</td>
                <td>{new Date(booking?.bookingDate).toDateString()}</td>
                <td>{`${booking?.currency} - ${booking?.price}`}</td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
}
