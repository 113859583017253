import { useEffect, useState } from 'react';
import GoalsTable from '../../components/user-credits/GoalsTable';
import TotalConfemyCreditsTable from '../../components/user-credits/TotalConfemyCreditsTable';
import TotalExternalCreditsTable from '../../components/user-credits/TotalExternalCreditsTable';
import { scrollToTop } from '../../utility/commonUtil';

const UserCreditsPage = () => {
  const [totalStartDate, setTotalStartDate] = useState(null);
  const [totalEndDate, setTotalEndDate] = useState(null);
  const [confTotalStartDate, setConfTotalStartDate] = useState(null);
  const [confTotalEndDate, setConfTotalEndDate] = useState(null);
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <div>
      <div className="mb-40">
        <GoalsTable />
      </div>
      <div className="mb-40">
        <TotalConfemyCreditsTable
          confTotalStartDate={confTotalStartDate}
          setConfTotalStartDate={setConfTotalStartDate}
          confTotalEndDate={confTotalEndDate}
          setConfTotalEndDate={setConfTotalEndDate}
        />
      </div>

      <div className="mb-40">
        <TotalExternalCreditsTable
          totalStartDate={totalStartDate}
          totalEndDate={totalEndDate}
          setTotalStartDate={setTotalStartDate}
          setTotalEndDate={setTotalEndDate}
        />
      </div>
      {/* <div className="mb-40">
        <ConfemyCredits
          confStartDate={confStartDate}
          setConfStartDate={setConfStartDate}
          confEndDate={confEndDate}
          setConfEndDate={setConfEndDate}
        />
      </div> */}

      {/* <div className="mb-40">
        <VideoCourseCredits
          confTotalStartDate={confTotalStartDate}
          confTotalEndDate={confTotalEndDate}
        />
      </div> */}
      {/* <div className="mb-80">
        <ExternalCreditsTable
          totalStartDate={totalStartDate}
          totalEndDate={totalEndDate}
          externalStartDate={externalStartDate}
          externalEndDate={externalEndDate}
          setExternalStartDate={setExternalStartDate}
          setExternalEndDate={setExternalEndDate}
        />
      </div> */}
      {/* <ManageExternalCredits /> */}
    </div>
  );
};

export default UserCreditsPage;
