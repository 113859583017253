import { useEffect } from 'react';
import VideoCourseEarning from '../../components/video-course/video-course-earnings/VideoCourseEarnings';
import { scrollToTop } from '../../utility/commonUtil';
export default function VideoCourseEarningsPage() {
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <>
      <VideoCourseEarning />
    </>
  );
}
