export default function Conditions() {
  return (
    <div style={{ padding: '4% 5% 5%' }} className="container-lg">
      <h2 className="color-primary mb-32">
        Confemy a product of Portamed Private Limited.
      </h2>
      <h2 className="color-primary mb-40">Conditions</h2>
      <div className="caption-2-regular-gray3">
        <h4 className="color-primary mb-16">Right to cancel or postpone</h4>
        <p className="mb-16">
          Confemy reserves the right to cancel or postpone any course due to
          unforeseen circumstances. In the unlikely event that Confemy must
          cancel or postpone this course, we will fully refund the registration
          fee, but are not responsible for any related costs, charges or
          expenses to participants, including fees assessed by airline, travel
          or lodging agencies.
        </p>
        <h4 className="color-primary mb-16">Copyright</h4>
        <p className="mb-16">
          The content, organization, graphics, design, and other matters related
          to the site are protected under applicable copyrights and other
          proprietary laws, including but not limited to intellectual property
          laws. The copying, reproduction, use, modification or publication by
          you of any such matters or any part of the site is strictly
          prohibited, without our express prior written permission.
        </p>
        <h4 className="color-primary mb-16"> Minors</h4>
        <p className="mb-16">
          Minors - under the age of 18 are not allowed to attend activities,
          either as guests or as paid registrants, except under approved
          circumstances.
        </p>
        <p className="mb-16">
          1. Individual exception requests will be taken under consideration.{' '}
        </p>
        <p className="mb-16">
          2. If a minor is in attendance, staff have the right to ask minor to
          leave if the individual is disruptive.
        </p>
        <h4 className="color-primary mb-16">Attendance</h4>
        <p className="mb-16">
          Attendance at a Confemy course does not indicate nor guarantee
          competence or proficiency in the performance of any procedures which
          may be discussed or taught.
        </p>
        <h4 className="color-primary mb-16">Travel and lodging</h4>
        <p className="mb-16">
          All travel and lodging expenses are the sole responsibility of the
          individual registrant.
        </p>
        <p className="mb-16">
          Any use of this site constitutes your agreement to the Terms and
          Conditions of Online Registration and the Terms and Conditions and
          Privacy Policy linked below.
        </p>
      </div>
    </div>
  );
}
