import { useNavigate } from 'react-router-dom';
import BackButton from '../button/BackButton';

export default function Privacy() {
  const navigate = useNavigate();
  return (
    <div style={{ padding: '5%' }} className="container-lg">
      <div className="mb-32">
        <BackButton className="green" onBackButtonClick={() => navigate(-1)} />
      </div>
      <h2 className="color-primary mb-32">
        Confemy a product of Portamed Private Limited.
      </h2>
      <h2 className="color-primary mb-32">Our Terms of Service</h2>
      <div className="caption-2-regular-gray3 mb-40">
        <p className="mb-16">
          These Terms of Service and Privacy Policy describe how the Confemy web
          application ("Confemy", or "our App") for the organization in which
          you have been authorized for its use ("Organization"), shall be used,
          and how selected information is handled within our App.
        </p>
        <p className="mb-16">
          Depending upon your role, this could be regarding your action to
          create a profile, sign up for a course, take a course, exhibit at a
          course and/or elect to receive marketing information or host a course
          and organize a conference. Confemy will not maintain their
          accreditation status. It's sole responsibility of course offering
          individula or organization to maintain acrredation, share information
          with appropriate accreditation and licensure boards, as needed, to
          provide course credits.
        </p>
        <p className="mb-16">
          Confemy may modify these terms and conditions at any time, and such
          modifications shall be effective immediately upon posting the modified
          terms and conditions. You agree to review the agreement periodically
          to be aware of such modifications, and your use of the Confemy website
          constitutes your acceptance of the agreement as it appears at the time
          of your access or use.
        </p>
        <p className="mb-16">
          If you choose to use other websites or applications offered by Confemy
          or its affiliated entities, you may be asked to enter into additional
          agreements or authorizations before you can access features or
          services. If you are not asked to enter into additional or different
          agreements, these terms will apply. By agreeing to these terms and
          conditions of use, you authorize Confemy and its staff to communicate
          and provide information to you regarding Continued Medical Education
          and services we offer.{' '}
        </p>
      </div>
      <h2 className="color-primary mb-40">Privacy Policy</h2>
      <div className="caption-2-regular-gray3">
        <h4 className="color-primary mb-16">Overview</h4>
        <p className="mb-16">
          Please read our Privacy Policy below to understand what information we
          collect and how that information will be used and disclosed in
          connection with providing you with Confemy. Please note that
          individual web services offered by Confemy or its affiliates that you
          may access while using Confemy services may be subject to different or
          additional privacy policies. If those sites collect any identifiable
          information, you will be given the opportunity to review and approve
          those policies before accessing those services.
        </p>
        <p className="mb-16">
          Confemy is committed to protecting your privacy. We feel it is
          important for you to know how we collect, use and disclose the
          information you provide when you interact with Confemy services. This
          information can be used to access various services provided by Confemy
          and its affiliated entities. By agreeing to this Privacy Policy you
          agree that we may collect, use and disclose Information about you as
          set forth in this Policy. Your agreement and participation are always
          voluntary, but if you don’t agree, you will not be able to receive
          Confemy services. If you use Confemy to access its affiliates, the
          information collected by those groups may be subject to different or
          additional privacy policies, and you will be asked to review and agree
          to those privacy policies before you access those services.
        </p>
        <h4 className="color-primary mb-16">
          1. Information we collect, and how we use it.
        </h4>
        <p className="mb-16">
          Information you provide to create a profile and pay for products and
          services. This is all the personal and professional information that
          is needed to interact with Confemy services. We use this information
          to verify your identity and your eligibility to receive Confemy
          services. We also use certain items of this information, such as your
          email and answers to password verification questions to validate your
          identity if you need to recover your account password. If you choose
          to purchase products or services from us we also collect information
          necessary to process your payment and use it to process your payment
          transaction.
        </p>
        <p className="mb-16">
          Information we collect automatically. The Web server automatically
          collects the Internet Protocol (IP) address of the computers that
          access our site. An IP address is a number that is assigned to your
          computer when you access the Internet. We use this information to
          understand how our site is being used and how we can better serve
          visitors. We also collect information about visitors to our site using
          "cookies" (see definition on Webopedia) and similar technology such as
          Web beacons, Web bugs, pixel tags, and so on. We use this technology
          to recognize a repeat visitor and offer the visitor a set of services
          or information requested in a previous visit. We use session cookies
          to track a visitor's path through our site during a visit, to help us
          understand how people use our site.
        </p>
        <p className="mb-16">
          We use Google Analytics, a web analytics service provided by Google,
          Inc., on our Websites. Google Analytics uses cookies or other tracking
          technologies to help us analyze how users interact with and use the
          Websites, compile reports on the Websites’ activity, and provide other
          services related to Websites activity and usage. The technologies used
          by Google may collect information such as your IP address, time of
          visit, whether you are a return visitor, and any referring website.
          This Site does not use Google Analytics to gather information that
          personally identifies you. The information generated by Google
          Analytics will be transmitted to and stored by Google and will be
          subject to Google’s privacy policies. To learn more about Google’s
          partner services and to learn how to opt out of tracking of analytics
          by Google click here.
        </p>

        <p className="mb-32">
          Your browser or device may include “Do Not Track” functionality. At
          this time, we do not respond to browser “Do Not Track” signals.
        </p>
        <h4 className="color-primary mb-16">
          2. How We May Share the information you provide
        </h4>
        <p className="mb-16">
          If you choose to use Cofemy services to access services provided by
          Cofemy or any of its affiliated entities, we may share the information
          we collect with the entity providing their services for the purposes
          of verifying your identity, your eligibility to access the services,
          and to provide the affiliates’ services.
        </p>
        <p className="mb-16">
          We may also share your personally identifiable information with a
          third party if we believe that disclosure is necessary to conform to
          legal requirements or to respond to a subpoena, search warrant or
          other legal process received by us; to enforce the Terms of Use or to
          protect our legal rights; or to protect the safety of members of the
          public.
        </p>
        <p className="mb-16">
          We may also share the information you provide to us and that we
          collect about you to those that we have engaged in a joint partnership
          agreement or accreditation/licensure for purposes of providing Confemy
          services.
        </p>
        <p className="mb-16">
          We may share your personal information with third parties who we have
          engaged to help us provide the services. In each case, we will ensure
          that these third parties have agreed to safeguard your data.
        </p>

        <h4 className="color-primary mb-16">
          3. Protecting Children's privacy
        </h4>
        <p className="mb-16">
          We are committed to protecting children’s privacy on the Internet.
          This site is intended for adults age 16 and older. We do not knowingly
          collect personal information from children. If you are under the age
          of 16, please have a parent/guardian contact us at
          portamedservices@gmail.com.
        </p>
        <p className="mb-32"></p>
        <h4 className="color-primary mb-16">4. Data Retention </h4>
        <p className="mb-32">
          We will retain your information for as long as your account is active
          or as needed to provide you services, comply with our legal
          obligations, resolve disputes, and enforce our agreements.
        </p>
        <h4 className="color-primary mb-16">
          5. Data Processed and Stored in India
        </h4>
        <p className="mb-16">
          You understand and agree that the information provided by you is
          transmitted to, processed in, and stored in India.
        </p>
        <h4 className="color-primary mb-16">
          6. Protecting Your California Privacy Rights
        </h4>
        <p className="mb-16">
          We do not share your personal information with unaffiliated third
          parties for their direct marketing purposes. We may, however, share
          your personal data with any Confemy entities or affiliate. You have
          the right to opt-out of such sharing for marketing purposes.
        </p>
        <h4 className="color-primary mb-16">
          7. Users in the European Economic Area (EEA) and Switzerland
        </h4>
        <p className="mb-16">
          If you are a resident of the EEA or Switzerland, the following
          information applies with respect to personal data collected through
          your use of our site.
        </p>
        <p className="mb-16">
          Purposes of processing and legal basis for processing: As explained
          above, we process personal data in various ways depending upon your
          use of the services. We process personal data on the following legal
          bases: (1) with your consent; (2) as necessary to provide the
          services; and (3) as necessary for our legitimate interests in
          providing the services where those interests do not override your
          fundamental rights and freedom related to data privacy.
        </p>
        <p className="mb-16">
          Transfers: Personal data we collect may be transferred to, and stored
          and processed in, India, the United States or any other country in
          which we or our affiliates or processors maintain facilities. We will
          ensure that transfers of personal data to a country or an
          international organization outside the EEA or Switzerland are subject
          to appropriate safeguards.
        </p>
        <p className="mb-16">
          Your Rights: You are entitled to the rights under Chapter III of the
          EU General Data Protection Regulation or Section 2 of the Swiss
          Federal Act on Data Protection with respect to the processing of your
          personal data, which include the right to access and rectify and to
          request erasure of personal data. In order to verify your identity, we
          may require you to provide us with personal information prior to
          accessing any records containing information about you.
        </p>
        <h4 className="color-primary mb-16">8. Complaint process</h4>
        <p className="mb-16">
          If you have a complaint or problem, or if you believe your privacy
          rights have been violated, you may email us by clicking the "Contact
          us" link. Please indicate the following reason for contacting us:
          General Question. Our Customer Service department will respond within
          5 business days. If you don't receive adequate resolution of a
          privacy-related problem, you may write to CONFEMY's security and
          privacy officer at:Confemy [A-103, Amits Bloomfield, Ambegaon BK,
          Pune, India 411046]. Effective April 14, 2017
        </p>
        <h4 className="color-primary mb-16">9. Update process</h4>
        <p className="mb-32">
          Changes to our Privacy policy will occasionally update this Privacy
          policy. You will see the word "updated" followed by the date next to
          the Privacy policy link near the bottom of all pages on the site. If
          the "updated" date has changed since you last visited, you're
          encouraged to review the policy to be informed of how this site is
          protecting your information.
        </p>
        <h4 className="color-primary mb-16">SECURITY</h4>
        <p className="mb-32">
          The security of any data you submit via this site is very important to
          us. This site uses encryption technology, and we constantly review our
          security practices to help ensure that your data is maintained
          securely. However, no system can perfectly guard against risks of
          intentional intrusion or inadvertent disclosure of information sent to
          us. Moreover, when you transmit information via the internet, your
          information will be transmitted over a medium that is beyond our
          control, and therefore the security of the transmission may be
          compromised before it reaches us. Accordingly, CONFEMY makes no
          guarantee as to confidentiality or security. IN ADDITION, YOU HEREBY
          EXPRESSLY AND SOLELY ASSUME THE RISK OF ANY UNAUTHORIZED DISCLOSURE OR
          INTENTIONAL INTRUSION, OR OF ANY DELAY, FAILURE, INTERRUPTION OR
          CORRUPTION OF DATA OR OTHER INFORMATION TRANSMITTED IN CONNECTION WITH
          YOUR USE OF THE ONLINE sERVICES. Your user name and password should be
          kept confidential. Failure to do so may also compromise the
          confidentiality of your information.
        </p>
        <h4 className="color-primary mb-16">NO WARRANTIES</h4>
        <p className="mb-32">
          YOU UNDERSTAND AND AGREE THAT THE CONFEMY sERVICES ARE PROVIDED ON AN
          "AS IS" BASIS. CONFEMY DOES NOT MAKE ANY EXPRESS OR IMPLIED
          WARRANTIES, REPRESENTATIONS OR ENDORSEMENTS WHATSOEVER (INCLUDING
          WITHOUT LIMITATION WARRANTIES OF TITLE OR NONINFRINGEMENT, OR THE
          IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR
          PURPOSE) WITH REGARD TO THE SERVICE OR ANY MERCHANDISE, INFORMATION OR
          SERVICE PROVIDED IN CONNECTION WITH THE CONFEMY sERVICES ACCOUNT. IN
          ADDITION, CONFEMY DOES NOT GUARANTEE THAT USE OF THE CONFEMY sERVICES
          WILL BE FREE FROM TECHNOLOGICAL DIFFICULTIES INCLUDING, BUT NOT
          LIMITED TO, UNAVAILABILITY OF INFORMATION, DOWNTIME, SERVICE
          DISRUPTIONS, VIRUSES OR WORMS AND YOU UNDERSTAND THAT YOU ARE
          RESPONSIBLE FOR IMPLEMENTING SUFFICIENT PROCEDURES AND CHECKPOINTS TO
          SATISFY YOUR PARTICULAR REQUIREMENTS FOR ACCURACY OF DATA INPUT AND
          OUTPUT.
        </p>
        <h4 className="color-primary mb-16">LIMITATION OF LIABILITY</h4>
        <p className="mb-32">
          THE USE OF THE CONFEMY SERVICES IS ENTIRELY AT YOUR OWN RISK, AND IN
          NO EVENT SHALL CONFEMY BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL,
          CONSEQUENTIAL, SPECIAL, EXEMPLARY, PUNITIVE, OR ANY OTHER MONETARY OR
          OTHER DAMAGES, FEES, FINES, PENALTIES, OR LIABILITIES ARISING OUT OF
          OR RELATING IN ANY WAY TO THE CONFEMY SERVICES, OR SITES ACCESSED
          THROUGH THE CONFEMY SERVICES, AND/OR CONTENT OR INFORMATION PROVIDED
          HEREIN. YOUR SOLE AND EXCLUSIVE REMEDY FOR DISSATISFACTION WITH THE
          SERVICE IS TO STOP USING THE SERVICE.
        </p>
        <h4 className="color-primary mb-16">USER AGREEMENTS</h4>
        <p className="mb-32">
          You agree: The information you provide in connection with Confemy
          services is true and accurate and pertains to yourself; You will not
          use the Confemy services for any illegal purpose, or in a manner which
          violates local, state, national or international laws; You will not
          use the Confemy services to post or transmit material that may
          infringe or violate any rights of any party or is unlawful, obscene,
          defamatory, threatening, harassing, abusive, slanderous, hateful or
          embarrassing to any other person or entity;
        </p>
        <h4 className="color-primary mb-16">DISCONTINUANCE OF SERVICE</h4>
        <p className="mb-32">
          Confemy services are being provided by Confemy as a courtesy to you,
          and Confemy reserves the right to terminate modify, discontinue,
          temporarily or permanently, the Confemy services at any time, with or
          without notice. In addition, Confemy may terminate, modify or limit
          any or all of the Confemy services if you violate the terms of this
          Agreement or any other agreement with Confemy , you violate any law,
          or if we determine, for any other reason, that it is no longer
          appropriate for you to use those Confemy services.
        </p>
        <h4 className="color-primary mb-16">
          COPYRIGHT AND TRADEMARK INFORMATION
        </h4>
        <p className="mb-32">
          The marks "Confemy," and the triple-shield Confemy logo are trademarks
          and/or service marks of Portamed Pvt Limited. The content and design
          of websites operated by Confemy and its affiliates are protected by
          India, U.S. and international copyright laws. You may not copy,
          reproduce, republish, upload, post, display, transmit, or frame any of
          these materials without prior written consent from Confemy, except
          that you may view, download, display and print a single copy of these
          materials on a single computer for personal, noncommercial use only,
          so long as: (1) you do not alter or modify the materials in any way;
          (2) you include all applicable notices and disclaimers (including
          copyright notices); and (3) you do not use the materials in a way that
          suggests an association with Confemy or an affiliated Confemy entity.
          You understand and agree that title to these materials shall not pass
          to you or any other user.
        </p>
        <h4 className="color-primary mb-16">DISPUTE RESOLUTION</h4>
        <p className="mb-16">
          You agree that any dispute or claim (including personal injury claims)
          related to your use of Confemy services is subject to the exclusive
          jurisdiction of the state of Maharashtra, India, and that any such
          dispute shall be governed by the laws of the state of Maharashtra.
          This agreement also applies to anyone claiming on your behalf.
        </p>
        <p className="mb-16">Updated : 09.08.2023</p>
      </div>
    </div>
  );
}
