import { useEffect } from 'react';
import ExternalCreditTypes from '../../components/attendee-assets/ExternalCreditTypes';
import { scrollToTop } from '../../utility/commonUtil';
export default function ExternalCreditTypesPage() {
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <div>
      <ExternalCreditTypes />
    </div>
  );
}
