import React, { useEffect } from 'react';
import PodcastBooking from '../../components/podcast/PodcastBooking';
import { scrollToTop } from '../../utility/commonUtil';

export default function PodcastBookingPage() {
  useEffect(() => {
    scrollToTop();
  }, []);
  return <PodcastBooking />;
}
