import { useEffect } from 'react';
import Earnings from '../../components/earnings/Earnings';
import { scrollToTop } from '../../utility/commonUtil';
export default function EarningsPage() {
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <>
      <Earnings />
    </>
  );
}
