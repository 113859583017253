// import CreditRequests from "../../components/organizer-dashboard-conferences/CreditRequests";
import { useEffect } from 'react';
import CreditRequests from '../../components/credit-requests/CreditRequests';
import { scrollToTop } from '../../utility/commonUtil';

export default function CreditRequestsPage() {
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <div>
      <CreditRequests />
    </div>
  );
}
