import { useEffect } from 'react';
import AudioCourseCreditRequests from '../../components/audio-course/AudioCourseCredits';
import VideoCourseCreditRequests from '../../components/video-course/video-course-credits/VideoCourseCredits';
import { scrollToTop } from '../../utility/commonUtil';

export default function AudioCourseCreditRequestsPage() {
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <div>
      <AudioCourseCreditRequests />
    </div>
  );
}
