import { useEffect } from 'react';
import RefundDetails from '../../components/refund/RefundDetails';
import { scrollToTop } from '../../utility/commonUtil';
export default function RefundDetailsPage() {
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <>
      <div className="container pt-64">
        <RefundDetails />
      </div>
    </>
  );
}
