import React, { useEffect } from 'react';
import MyPodcast from '../../components/podcast/MyPodcast';
import { scrollToTop } from '../../utility/commonUtil';

export default function MyPodcastPage() {
  useEffect(() => {
    scrollToTop();
  }, []);
  return <MyPodcast />;
}
