import React, { useEffect } from 'react';
import VideoCourseBooking from '../../components/video-course/organiser-bookings/VideoCourseBookings';
import { scrollToTop } from '../../utility/commonUtil';

export default function OrganiserVideoBookingPage() {
  useEffect(() => {
    scrollToTop();
  }, []);
  return <VideoCourseBooking />;
}
