import { useEffect } from 'react';
import VideoCourseRefunds from '../../components/refund/VideoCourseRefunds';
import { scrollToTop } from '../../utility/commonUtil';
export default function VideoCourseRefundsPage() {
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <>
      <VideoCourseRefunds refunded={true} />
    </>
  );
}
